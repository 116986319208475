<template>
  <b-card
    v-if="valid"
    :class="{
      disabled,
      'product-selected': slideProductSelected(hydratedSlideProduct)
    }"
    class="product-card-component"
    footer-bg-variant="transparent"
    @click="choose"
  >
    <div v-if="slideProductSelected(hydratedSlideProduct)">
      <fa-icon class="cart-icon" icon="shopping-cart" />
    </div>

    <div v-if="slideProduct.image_urls" class="card-image-container">
      <img
        :src="imageUrl"
        class="card-image"
        alt="slideProduct.title"
      >
    </div>

    <h4 class="product-title">
      {{ slideProduct.title }}
    </h4>

    <b-card-text class="text-left">
      <div v-html="productDescription" />
    </b-card-text>

    <template v-if="showFooter" #footer>
      <b-card-text
        v-if="showFiledIn"
        :class="{ 'complete-package': slideProduct.position === 1 }"
        class="filed-in-text"
      >
        <p v-if="filedIn">
          State Processing Time <span>{{ filedIn }}</span>
        </p>
        <i v-else>
          State Processing Time time varies
        </i>
      </b-card-text>

      <b-card-text v-if="!isFreeOrNullPrice">
        <div>
          <p>{{ priceDescription }}</p>
        </div>
        <div>
          <b>{{ `$${price}` }}</b>
        </div>
        <div>
          <p class="info">
            {{ purchaseInfo }}
          </p>
        </div>
      </b-card-text>

      <b-button
        v-if="slideProduct.button_text"
        variant="primary"
        class="borderless"
        aria-label="select"
      >
        <b>
          {{ slideProduct.button_text }}
        </b>
      </b-button>
    </template>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import Pluralize from 'pluralize'
import * as DOMPurify from 'dompurify'

export default {
  name: 'ProductCard',
  props: {
    slideProduct: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    slideJurisdiction: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      products: [],
      loaded: false,
      filedIn: null,
      mailFilingMethod: null,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'jurisdiction',
      'slideProductSelected',
      'productSlideProducts',
    ]),
    ...mapGetters('checkout', [
      'registeredAgentProductByJurisdiction',
      'raServiceActiveOrInCart',
      'boiFiling',
    ]),
    ...mapGetters('companies', [
      'hasExistingRAServiceInJurisdiction',
      'currentCompany',
    ]),
    hydratedSlideProduct() {
      return this.productSlideProducts.find(slideProduct => slideProduct.id === this.slideProduct.id)
    },
    filingMethods() {
      return this.hydratedSlideProduct?.product?.filing_methods
    },
    hasNoFilingMethods() {
      return !this.filingMethods || this.filingMethods.length === 0
    },
    bundleOverride() {
      return this.hydratedSlideProduct.product?.product_bundle_override
    },
    bundleIncludesRA() {
      return this.bundleOverride?.ra_product_count > 0
    },
    showFooter() {
      return this.price !== null || this.isFreeOrNullPrice
    },
    isFreeOrNullPrice(){
      return this.slideProduct.product_kind === 'diy' &&
        (this.slideProduct.config.price == 0 || this.slideProduct.config.price === null) &&
        !this.diyFormACompanyFiling
    },
    showFiledIn() {
      return this.slideProduct.product_category === 'form a company'
    },
    isSubscription() {
      return this.hydratedSlideProduct.product !== undefined
        && this.slideProduct.product_kind === 'subscription_bundle'
    },
    priceDescription() {
      return this.isSubscription ? 'Pricing starts at' : 'One-Time Price'
    },
    productDescription() {
      let productDescription = this.slideProduct.description
      if (this.isSubscription) {
        productDescription = productDescription.replaceAll('::price-per-month::', this.price)
      }
      const newRegisteredAgentServiceLine = this.raServiceActiveOrInCart ? '' :
        this.bundleIncludesRA ? `<li>Registered Agent Service</li><p>1 year of service included</p>` :
        `<li>Registered Agent Service</li><p>1 year of service for $${this.raPrice()}</p>`
      productDescription = productDescription.replaceAll('<li>Registered Agent Service</li>', newRegisteredAgentServiceLine)
      return DOMPurify.sanitize(productDescription)
    },
    purchaseInfo() {
      if (this.isSubscription) {
        return `$${this.price} / month`
      } else if (this.diyFormACompanyFiling && this.price === 0) {
        return `Varies`
      }
      return `State Fees: $${this.price - this.ourFee} | Our Fee: $${this.ourFee}`
    },
    ourFee() {
      return this.bundleOverride?.price ||
        this.slideProduct?.product?.price ||
        0
    },
    valid() {
      return this.loaded
        && (
          this.hydratedSlideProduct.product !== undefined ||
          this.slideProduct.config.price !== undefined
        )
    },
    imageUrl() {
      return this.slideProduct.image_urls[0]
    },

    // Filing Types --------------------------------------------------------------------------------
    formACompanyFiling() {
      return this.slideProduct.product_kind === 'filing_product'
        && this.slideProduct.product_category === 'form a company'
        && this.slideProduct.product_sub_category === 'form a company'
    },
    eFileFormACompanyFiling() {
      return this.slideProduct.product_kind === 'filing_product'
        && this.slideProduct.product_category === 'form a company'
        && this.slideProduct.product_sub_category === 'efile form a company'
    },
    diyFormACompanyFiling() {
      return this.slideProduct.product_kind === 'diy' &&
        this.slideProduct.product_category === 'form a company'
    },

    // Prices --------------------------------------------------------------------------------------
    subscriptionMonthlyAmount: () => this.hydratedSlideProduct.product.monthly_amount,
    freeFormACompanyPrice: () => {
      return this.highestFilingMethodPrice() +
             this.freeFilingProcessingFee(this.hydratedSlideProduct.product)
    },
    diyFormACompanyPrice() { return this.mailFilingMethod?.cost ? this.mailFilingMethod.cost : 0 },
    slideProductPrice() { return this.slideProduct.config.price },
    productOverridePlusRaPrice() {
      return this.productOverridePriceForSlideJurisdiction() +
             this.highestFilingMethodPrice() +
             this.raCost()
    },
    filingProductPrice() {
      return this.bundleOverride?.price || this.hydratedSlideProduct.product.price
    },
    formACompanyFilingPrice() {
      const boiFilingPrice = this.boiFiling?.price || 0
      const raPrice = this.bundleIncludesRA ? 0 : this.raCost()

      return this.filingProductPrice +
        this.highestFilingMethodPrice() +
        boiFilingPrice +
        raPrice
    },
    eFileFormACompanyFilingPrice() {
      return this.filingProductPrice +
        this.lowestFilingMethodPrice() +
        this.raCost()
    },
    defaultPrice() {
      return this.filingProductPrice +
        this.highestFilingMethodPrice() +
        this.raCost()
    },
    price() {
      let price = 0
      if (!this.valid) return price

      if (this.isSubscription) {
        price = this.subscriptionMonthlyAmount
      } else if (this.hydratedSlideProduct.product?.name === 'Free Form a Company') {
        price = this.freeFormACompanyPrice
      } else if (this.diyFormACompanyFiling) {
        price = this.diyFormACompanyPrice
      } else if (this.slideProduct.config.price || this.isFreeOrNullPrice) {
        price = this.slideProductPrice
      } else if (this.productIsEligibleForCostOverride()) {
        price = this.productOverridePlusRaPrice
      } else if (this.formACompanyFiling) {
        price = this.formACompanyFilingPrice
      } else if (this.eFileFormACompanyFiling) {
        price = this.eFileFormACompanyFilingPrice
      } else {
        price = this.defaultPrice
      }
      price = price % 1 ? price.toFixed(2) : price

      return price
    },
  },
  async created() {
    this.loaded = false

    await this.fetchAndHydrateSlideProduct()

    if (this.diyFormACompanyFiling) {
      this.mailFilingMethod = await this.fetchMailFilingMethod({
        companyId: this.currentCompany.id,
        filingName: this.slideProduct.product_category,
        filingSubName: this.slideProduct.product_category,
        jurisdictionId: this.jurisdiction.id,
      })
    }

    this.setFiledAndReturnDocDates()
    this.loaded = true
  },
  methods: {
    ...mapActions('checkout', [
      'fetchProduct',
    ]),
    ...mapActions('filingMethods', [
      'fetchMailFilingMethod',
    ]),
    ...mapActions('stageline', [
      'addProductSlideProduct',
    ]),
    async fetchAndHydrateSlideProduct() {
      const products = await this.fetchProduct({
        productKind: this.slideProduct.product_kind,
        productCategory: this.slideProduct.product_category,
        productSubCategory: this.slideProduct.product_sub_category,
        jurisdictionId: this.jurisdiction.id,
      })

      const hydratedSlideProduct = this.slideProduct
      hydratedSlideProduct.product = products.shift()

      this.addProductSlideProduct(hydratedSlideProduct)
    },
    choose() {
      if (this.disabled) return
      this.$emit('chosen')
    },
    freeFilingProcessingFee(product) {
      const sort = (a, b) => { return b.cost - a.cost }
      const filingMethod = product.filing_methods.sort(sort)[0]

      return Math.round(filingMethod.cost * 0.05)
    },
    highestFilingMethodPrice() {
      if (this.hasNoFilingMethods) return 0
      return _.max(this.filingMethods.filter(fm => fm.purchasable === true).map(fm => fm.cost))
    },
    highestFilingMethod() {
      if (this.hasNoFilingMethods) return ''
      return this.filingMethods.reduce((prev, current) => (prev.cost > current.cost) ? prev : current)
    },
    lowestFilingMethodPrice() {
      if (this.hasNoFilingMethods) return 0
      return _.min(this.filingMethods.filter(fm => fm.purchasable === true).map(fm => fm.cost))
    },
    lowestFilingMethod() {
      if (this.hasNoFilingMethods) return ''
      return this.filingMethods.reduce((prev, current) => (prev.cost < current.cost) ? prev : current)
    },
    setFiledAndReturnDocDates() {
      let data = {}

      if (this.diyFormACompanyFiling) data = this.mailFilingMethod
      else if (this.eFileFormACompanyFiling) data = this.lowestFilingMethod()
      else data = this.highestFilingMethod()

      const filedInData = data?.filed_in

      if (filedInData?.days || filedInData?.hours) {
        this.filedIn = filedInData?.days ?
          `${filedInData.days} ${Pluralize('Business Day', filedInData.days)}` :
          `${filedInData.hours} ${Pluralize('Hour', filedInData.days)}`
      }
    },
    raPrice() {
      return this.currentCompany.entity_type !== 'Sub Series LLC' ?
        this.registeredAgentProductByJurisdiction(this.slideJurisdiction.state_province_region).price :
        0
    },
    raCost() {
      let raCost = 0
      if (!this.hasExistingRAServiceInJurisdiction(this.slideJurisdiction) &&
        this.enableRegisterAgentAddOn() && !this.raServiceActiveOrInCart) {
          raCost = this.raPrice()
      }
      return raCost
    },
    enableRegisterAgentAddOn() {
      return ['form a company', 'register a company'].includes(this.hydratedSlideProduct.product.filing_name) &&
        this.hydratedSlideProduct.product?.filing_sub_name !== 'efile form a company'
    },
    productIsEligibleForCostOverride() {
      if (!this.hydratedSlideProduct.product) return false

      return !this.slideProduct.config.price
        && !this.isFreeOrNullPrice
        && (!_.isEmpty(this.hydratedSlideProduct.product.jurisdiction_price_overrides)
        || !_.isEmpty(this.hydratedSlideProduct.product.local_jurisdiction_price_overrides))
    },
    productOverridePriceForSlideJurisdiction() {
      const ljpo = this.hydratedSlideProduct.product.local_jurisdiction_price_overrides
      const jpo = this.hydratedSlideProduct.product.jurisdiction_price_overrides
      let overridePriceForJurisdiction = 0

      if (Object.keys(ljpo).length) {
        let localJurisdictionPrice = []
        const highestFilingMethod = this.highestFilingMethod()

        if (highestFilingMethod && highestFilingMethod.local_jurisdiction_id in ljpo) {
          localJurisdictionPrice.push(ljpo[highestFilingMethod.local_jurisdiction_id])
        }

        overridePriceForJurisdiction += localJurisdictionPrice.length ?
          _.max(localJurisdictionPrice) :
          this.hydratedSlideProduct.product.price

      } else if (this.slideJurisdiction.id in jpo) {
        overridePriceForJurisdiction += jpo[this.slideJurisdiction.id]
      } else {
        overridePriceForJurisdiction += this.hydratedSlideProduct.product.price
      }

      return overridePriceForJurisdiction
    },
  },
}
</script>

<style lang="scss" scoped>
  .product-selected {
    border: solid 0.125em $ct-ui-primary !important;
    background-color: #f2f3f4;
  }
  .product-card-component {
    @include ct-ui-selectable;
    &.disabled {
      cursor: default;
    }
  }
  .card {
    @include ct-ui-card-shadow;

    border-radius: 0.625em;
    margin-bottom: 1em;
    min-height: 25em;
    min-width: 17.5em;
    max-width: 18.75em;
    text-align: center;
  }
  .card-body {
    padding: 1.25rem 1.25rem 0;
  }
  .card-title {
    margin-top: 0.625em;
    margin-bottom: 1.125em;
  }
  .card-text {
    font-size: 0.875em;
    display: flex;
      flex-flow: column;

      p {
        margin-bottom: 0;
      }
      p.info {
        font-size: 0.8em;
        margin-top: 0.25em;
        margin-bottom: 0.75em;
      }
      b {
        font-size: 2.6em;
        color: $ct-ui-color-5;
        margin-bottom: 0.25rem;
      }
  }
  .product-title {
    margin-top: 1em;
    font-weight: 700;
  }
  .btn {
    padding: 0.75em;
    margin-top: 0.3125em;
    font-size: 0.875em;
    width: 100%;
  }
  .card-footer {
    border: none;
    padding: 0.75rem 0;
  }

  ::v-deep ul {
    list-style-type: none;
    text-align: center;
    padding-left: 0;

    li {
      padding: 0.25rem 0;
    }
    p {
      margin-top: -0.3125em;
      font-weight: 900;
      font-size: 0.875em;
    }
  }

  .filed-in-text {
    padding: 0.25rem;
    font-size: 1em;
    margin-bottom: 1.25rem;
  }
  .filed-in-text.complete-package {
    background-color: $ct-ui-primary;
    color: white;
  }
  .filed-in-text:not(.complete-package) {
    background-color: rgba(0, 0, 0, 0.1);
    color: black;
  }


  .cart-icon {
    background-color: $ct-ui-primary;
    color: white;
    padding: 0.625em;
    width: 1.25em;
    height: 1.25em;
    position: absolute;
    right: -0.625em;
    top: -0.625em;
    border-radius: 1.25em;
  }

  div.card-image-container {
    height: 9.375em;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    img.card-image {
      max-height: 9.375em;
      max-width: 10.9375em;
      padding: 1.25rem;
    }
  }
</style>
